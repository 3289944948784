import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private navController: NavController) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const accessToken = localStorage.getItem('bookguru-token');
    console.log('state url', state.url);
    if (
      typeof accessToken !== 'undefined' &&
      accessToken !== '' &&
      accessToken !== null
    ) {
      if (state.url === '/login' || state.url === '/forgot-password' || state.url === '/onboarding') {
        this.navController.navigateRoot('/home');
      } else {
        return true;
      }
    //   return false;
    } else {
        console.log('no token');
        if (!(state.url === '/login' || state.url === '/forgot-password' || state.url === '/onboarding')) {
            this.navController.navigateRoot('/login');
            } else {
                return true;
            }
        }
    }
}
