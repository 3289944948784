import { Component, ViewChildren, QueryList } from '@angular/core';
import { Platform, MenuController, NavController, IonRouterOutlet, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import jwt_decode from 'jwt-decode';
import { isAfter } from 'date-fns';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { endpoints } from 'src/common/endpoints';
import { HttpService } from './services/http-service/http.service';
import { GeneralService } from './services/generale-service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  profile: any;
  profileImage: string;
  initials: string;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private menuCtrl: MenuController,
    private navController: NavController,
    private router: Router,
    private oneSignal: OneSignal,
    private httpService: HttpService,
    public alertController: AlertController,
    private generalService: GeneralService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    console.log = () => {
    };
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.configureMenu();
      this.backButtonEvent();
      this.checkTokenExpiration();
      this.registerAndHandleOneSignal();
    });
  }

  configureMenu() {
    const hasToken = localStorage.getItem('bookguru-token');
    this.menuCtrl.get().then((menu: HTMLIonMenuElement) => {
      menu.swipeGesture = hasToken ? true : false;
    });
  }

  logout() {
    this.menuCtrl.close();
    this.configureMenu();
    localStorage.clear();
    this.navController.navigateRoot('/login');
    // const deviceId = localStorage.getItem('deviceId');
    // this.httpService.delete(endpoints.removeDevice(deviceId))
    //     .subscribe((res) => {
    //         console.log(res);
    //         localStorage.clear();
    //         // localStorage.removeItem('bookguru-token');
    //         // localStorage.removeItem('bookguru-subscriber-image');
    //         // localStorage.removeItem('bookguru-subscriber-initials');
    //         // this.profileImage = '';
    //         this.navController.navigateRoot('/login');
    //     });
  }

  navigateToPage(url: string) {
    this.menuCtrl.close();
    this.navController.navigateRoot(url);
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(() => {
      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        if (this.router.url === '/login' || this.router.url === '/onboarding' || this.router.url === '/home') {
          navigator['app'].exitApp();
        } else {
          this.navController.back();
        }
      });
    });
  }

  checkTokenExpiration() {
    if (localStorage.getItem('bookguru-token')) {
      const payload: any = jwt_decode(localStorage.getItem('bookguru-token'));
      const currDate = new Date();
      const expDate = new Date(payload.exp * 1000);
      if (isAfter(currDate, expDate)) {
        this.logout();
      }
    }

  }

  registerAndHandleOneSignal(): void {
    this.oneSignal.startInit('4cb5b163-cc83-454d-9eda-ecd412c576f4', '977967409704');
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
    this.oneSignal.handleNotificationReceived()
      .subscribe((response: any) => {
        console.log(response);
      });
    this.oneSignal.handleNotificationOpened()
      .subscribe((response: any) => {
        console.log(response);
      });
    this.oneSignal.endInit();
  }

  menuOpened() {
    console.log('here');
    this.profileImage = localStorage.getItem('bookguru-subscriber-image');
    this.initials = localStorage.getItem('bookguru-subscriber-initials');
    this.profile = JSON.parse(localStorage.getItem('bookguru-user-profile'));
  }

  async progressTracker(ev: any) {
    const alert = await this.alertController.create({
      header: 'Password',
      message:
        '<p class="steps-for-quiz top-pad-20 text-center">Please Enter The Progress Tracker Password</p>',
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: '********'
        }
      ],

      buttons: [
        {
          text: 'Submit',
          cssClass: 'alert-btn',
          handler: (data) => {
            //  this.navController.navigateRoot('/progress-tracker')
            if (data.password) {
              this.httpService.put(endpoints.progressPassword(), { password: data.password }).subscribe(
                (response: any) => {
                  console.log('TCL: Progress Password -> response', response);
                  if (response.error === false) {
                    this.router.navigate(['/progress-tracker'], { queryParams: { data: JSON.stringify(response) } });
                  }


                },
                (error: any) => {
                  console.log('TCL: Progress Password -> error', error);
                  this.generalService.showToastr(error.error.reason, 3000);
                }
              );
            } else {
              return false;
            }
          }
        }
      ],
      cssClass: 'take-quiz-popup-wrap new-password-popup progress-tracker-password'
    });

    await alert.present();
  }

}
