import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import { config } from 'src/common/config';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  profile: any = '';
  interestLevels: any;

  constructor(
    private toastrController: ToastController,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) {
    this.interestLevels = config.interestLevels;
  }

  async showToastr(message: string, duration: number = 3000): Promise<any> {
    const toastr = await this.toastrController.create({
      message,
      duration
    });
    toastr.present();
  }

  /**
   * function to update the interest level based on below values from config.interestLevels
   *  1: 'Lower Years (Tiny Tots - Grade 3)',
   *  2: 'Middle Years (Grade 4 and above)',
   *  3: 'Tween Years (Grade 6 and above)',
   */
  getInterestLevel(levels) {
    if (typeof (levels) === 'string') {
      return (this.interestLevels[levels]);
    } else {
      let arr = '';
      if (levels[0] === 2 && levels[1] === 3) {
        arr = 'Middle & Tween Years (Grade 4 and above)';
      } else if (levels[0] === 1 && levels[1] === 2) {
        arr = 'Lower & Middle Years (Upto Grade 6)';
      } else {
        arr = this.interestLevels[levels];
      }
      return arr;
    }
  }


  /* Preloader */
  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
      translucent: true,
      cssClass: 'custom-class custom-loading',
    });
    loading.present();
    return await loading;
  }
  /* Preloader */


  async downloadImage(imageUrl, name) {
    const a = document.createElement('a');
    a.href = await this.toDataURL(imageUrl);
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async toDataURL(url) {
    return fetch(url, { cache: 'no-cache' }).then((response) => response.blob()).then(blob => URL.createObjectURL(blob));
  }

  async presentAlert(header, subHeader, inputs, message, buttons, cssClass = '') {
    const alert = await this.alertController.create({
      header,
      subHeader,
      inputs,
      message,
      buttons,
      cssClass,

    });
    await alert.present();
  }
}
