import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AngularEpubViewerModule } from 'angular-epub-viewer';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpService } from './services/http-service/http.service';
import { InterceptorService } from './services/interceptor/interceptor.service';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // PdfViewerModule,
    AngularEpubViewerModule,
    NgxChartsModule,
    MarkdownModule.forRoot()
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StatusBar,
    OneSignal,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    HttpService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
