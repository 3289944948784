export const endpoints = {
  /**
   * @description Authenticate an user and get a JWT on success
   */
  login(): string {
    return 'login';
  },
  /**
   * @description Request to send forgotten password
   */
  forgotpassword(): string {
    return 'forgotpass';
  },
  /**
   * @description Search for a book to read
   */
  searchBooks(): string {
    return 'me/findbooks';
  },
  /**
   * @description Search for a book to read in more books to read
   */
  searchMoreBooks(): string {
    return 'me/findmorebooks';
  },
  /**
   * @description 1. GET - Fetch my profile
   * @description 2. PUT - Request admin to edit profile details
   */
  profile(): string {
    return 'me';
  },
  /**
   * @description Send help query to admins
   */
  help(): string {
    return 'me/help';
  },
  /**
   * @description Fetch your currently borrowed books (includes _pending_ requests for buying/cancelling & reported lost too)
   */
  currentList(): string {
    return 'me/currentlist';
  },
  /**
   * @description Fetch all entries in your ReadingList (including historical)
   */
  readingLists(): string {
    return 'me/readinglists';
  },
  /**
   * @description Find a ReadingList entry by _id
   * @param id The _id of your ReadingList to find
   */
  readingList(id: string): string {
    return 'me/readinglist' + id;
  },
  /**
   * @description Borrow a book
   */
  borrowBook(): string {
    return 'me/readinglist';
  },
  /**
   * @description Return a Book in your Reading List after reading it
   * @param id The _id of your ReadingList entry to return
   */
  returnBook(id: string): string {
    return 'me/readinglist/return/' + id;
  },
  /**
   * @description Reissue a Book in your Reading List because you did'nt finish reading it
   * @param id The _id of your ReadingList entry to reissue
   */
  reissueBook(id: string): string {
    return 'me/readinglist/reissue/' + id;
  },
  /**
   * @description Request to Cancel a Book in your Reading List because you did'nt like reading it
   * @param id The _id of your ReadingList entry to cancel
   */
  cancelBook(id: string): string {
    return '/me/readinglist/cancel/' + id;
  },
  /**
   * @description Request to Buy a Book in your Reading List
   * @param id The _id of your ReadingList entry to buy
   */
  buyBook(id: string): string {
    return 'me/readinglist/buy/' + id;
  },
  /**
   * @description Report a Book in your Reading List as Lost
   * @param id The _id of your ReadingList entry to report lost
   */
  lostBook(id: string): string {
    return 'me/readinglist/lost/' + id;
  },
  /**
   * @description Add a unique device id to the current Subscriber. For usage with OneSignal push notifications
   */
  addDevice(): string {
    return 'device';
  },
  /**
   * @description Remove a device id from the current Subscriber. For usage with OneSignal push notifications
   */
  removeDevice(id: string): string {
    return 'device/' + id;
  },

  getAvaialableSlots() {
    return 'me/slotsavailable';
  },

  submitFeedback(id) {
    return '/me/readinglist/feedback/' + id;
  },

  progressPassword() {
    return 'user/progress/report';
  },

  libraryBook() {
    return 'library/books';
  },

  searchBookFree() {
    return 'books';
  },

  borrowDate() {
    return 'me/return/date';
  },

  /**
   * @description 1. PUT - Request to update the stars
   */
  updateStars(id): string {
    return '/user/starupdate/' + id;
  },

  /**
   * @description 1. GET - Check availability of book
   */
  bookAvailabilityUrl(bookId): string {
    return `books/${bookId}/availablility`;
  },

  /**
   * @description 1. GET - Settings
   */
  settingsUrl(): string {
    return `settings`;
  },

  /**
   * @description 1. POST - Users
   */
  users(): string {
    return `users`;
  },

  /**
   * @description providing the feedback and rating on the time of return
   * @param id The _id of book
   */
  bookRating(id) {
    return 'me/readinglist/feedback/' + id;
  },
};
