import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth-guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
  },
  {
    path: 'program-details',
    loadChildren: () => import('./pages/program-details/program-details.module').then(m => m.ProgramDetailsPageModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'feedback/:id',
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackPageModule),
  },
  {
    path: 'search-book/:noOfBooks/:includeChallengeLevel',
    loadChildren: () => import('./pages/search-book/search-book.module').then(m => m.SearchBookPageModule),
  },
  {
    path: 'reading-history',
    loadChildren: () => import('./pages/reading-history/reading-history.module').then(m => m.ReadingHistoryPageModule),
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'progress-tracker',
    loadChildren: () => import('./pages/progress-tracker/progress-tracker.module').then(m => m.ProgressTrackerPageModule),
  },
  {
    path: 'library',
    loadChildren: () => import('./pages/library/library.module').then(m => m.LibraryPageModule),
  },
  {
    path: 'search-book-free/:noOfBooks/:includeChallengeLevel/:restrictionnlevel',
    loadChildren: () => import('./pages/search-book-free/search-book-free.module').then(m => m.SearchBookFreePageModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule),
  },
  {
    path: 'books/:bookId/readinglist/:readingListId/questions',
    loadChildren: () => import('./pages/quiz/quiz.module').then(m => m.QuizPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
  },
  {
    path: 'reading-jams',
    loadChildren: () => import('./pages/reading-jams/reading-jams.module').then(m => m.ReadingJamsPageModule)
  },
  {
    path: 'book-club-meets',
    loadChildren: () => import('./pages/book-club-meets/book-club-meets.module').then(m => m.BookClubMeetsPageModule)
  },
  /* Routes added by Bharti. As per requirement these two pages are added */
  {
    path: 'badges',
    loadChildren: () => import('./pages/badges/badges.module').then(m => m.BadgesPageModule)
  },
  {
    path: 'myworld',
    loadChildren: () => import('./pages/myworld/myworld.module').then(m => m.MyworldPageModule)
  },
  {
    path: 'my-earnings',
    loadChildren: () => import('./pages/my-earnings/my-earnings.module').then( m => m.MyEarningsPageModule)
  },
  /* Routes added by Bharti. As per requirement these two pages are added */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
