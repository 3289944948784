export const config = {
    // apiURL: 'http://localhost:3000/api/v1/', // Local
    // apiURL: 'http://206.189.142.84:3000/api/v1/', // Dev
    apiURL: 'https://api.bookguruonline.com/api/v1/', // Prod
    liveURL: '',
    interestLevels: {
        1: 'Lower Years (Tiny Tots - Grade 3)',
        2: 'Middle Years (Grade 4 and above)',
        3: 'Tween Years (Grade 6 and above)',
        // 4: 'Middle Years (Grade 4 and above)',
        // 5: 'Lower & Middle Years (Upto Grade 6)'
    },
    days: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    },
    gradeOptions: ['Tiny Tots', 'KG', 'Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6 & Above'],
    grades: [
        { title: 'Pre Nursery', value: -3 },
        { title: 'Nursery', value: -2 },
        { title: 'LKG', value: -1 },
        { title: 'UKG', value: 0 },
        { title: '1', value: 1 },
        { title: '2', value: 2 },
        { title: '3', value: 3 },
        { title: '4', value: 4 },
        { title: '5', value: 5 },
        { title: '6', value: 6 },
        { title: '7', value: 7 },
        { title: '8', value: 8 },
        { title: '9', value: 9 },
        { title: '10', value: 10 },
        { title: '11', value: 11 },
        { title: '12', value: 12 },
    ],
    bookType: ['eBook & Quiz', 'Quiz Only'],
    genresList: [
        'Adventure',
        'Alphabet',
        'Animals',
        'Arachnids',
        'Arts',
        'Behaviour',
        'Careers',
        'Character Traits',
        'Classics',
        'Clothing and Dress',
        'Comics & Cartoons',
        'Community Life',
        'Continents',
        'Counting Books',
        'Countries',
        'Diaries',
        'Disabilities',
        'Disasters',
        'Diseases',
        'Disorders',
        'Drama',
        'Emotions',
        'Fairy Tales',
        'Family Life',
        'Fantasy',
        'Folklore',
        'Food',
        'Funny',
        'Global Issues',
        'Good and Evil',
        'Graphic Novels',
        'Health',
        'Health & Wellness',
        'History',
        'Holidays',
        'Horror',
        'Humour',
        'Illness',
        'Imagination',
        'Insects',
        'Interpersonal Relationships',
        'Journals',
        'Learning',
        'Legends',
        'Letters',
        'Life',
        'Mystery',
        'Mythology',
        'Myths',
        'Natural Environments',
        'Occupations',
        'People',
        'Places',
        'Plants',
        'Poetry',
        'Recreation',
        'Regions',
        'Religion',
        'Rhymes',
        'School',
        'Science',
        'Science Fiction',
        'Seasons',
        'Social Issues',
        'Social Studies',
        'Solar System',
        'Sports',
        'Technology',
        'Thriller',
        'Transport',
        'U.S. States',
        'Universe',
        'Vehicles',
        'Wars',
        'Weather',
        'World'
    ],
    checkForPlanExpiry: (expiryDate) => (Date.parse(expiryDate) < Date.now()),
    checkForBookCycle: (expiryDate, cycleInDays) => {
        // @ts-ignore
        const diffDays = Math.floor((Date.parse(expiryDate) - Date.parse(new Date())) / (1000 * 60 * 60 * 24));
        return (diffDays >= cycleInDays);
    },
};
